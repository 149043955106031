<template>
  <aside class="lg-side">
    <!-- <div class="inbox-head">
            <h3>{{ currentView.title }}
            </h3>
        </div> -->

    <keep-alive>
      <component
        :is="currentView.tag"
        :data="currentView.data"
        @open-message="openMessage"
        @messages-updated="updateMessages"
        :mailConfiguration="mailConfiguration"
      ></component>
    </keep-alive>
  </aside>
</template>

<script>
import { bus } from "../../../main";
import Inbox from "./inbox.vue";
import Sent from "./sent.vue";
import Important from "./important.vue";
import Trash from "./trash.vue";
import ViewMessage from "./viewMessages.vue";

export default {
  props: {
    messages: {
      type: Array,
      required: true,
    },
    mailConfiguration: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      history: [
        {
          tag: "app-inbox",
          title: "Inbox",
          data: {
            messages: this.messages // Pass messages to inbox,
          },
        },
      ],
      currentFolder: "Inbox", // Default folder
      isMessageOpen: false, // Track if a message is open
    };
  },
  created() {
    bus.$on("changeView", (data) => {
      let temp = [
        {
          tag: data.tag,
          title: data.title,
          data: data.data || {},
        },
      ];
      this.isMessageOpen = false; // Reset message open state
      this.history = temp.concat(this.history.splice(0));
      // Do not change folder name on message view change
    });
  },
  methods: {
    openMessage() {
      this.isMessageOpen = true;
      // Don't update the folder name to message title
    },
    closeMessage() {
      if (this.previousView) {
        this.history.shift(); // Return to the previous view
      }
      this.isMessageOpen = false;
    },
    updateMessages(newMessages) {
      this.history[0].data.messages = newMessages; // Update messages in history
      this.$emit("messages-updated", newMessages); // 🔥 Emit to emails.vue
    }
  },
  computed: {
    currentView() {
      let current = this.history[0];
      current.data.messages = this.messages;
      return current;
    },
    previousView() {
      return typeof this.history[1] !== "undefined" ? this.history[1] : null;
    },
  },
  components: {
    appInbox: Inbox,
    appSent: Sent,
    appImportant: Important,
    appTrash: Trash,
    appViewMessage: ViewMessage,
  },
};
</script>
