<template>
  <div
    class="inbox-body"
    v-loading="loading"
    element-loading-text="Fetching messages..."
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(255, 255, 255, 0.8)"
  >
    <!-- Pass fetched inbox data to the child component -->
    <div class="header">
      <h2>Inbox</h2>
      <div class="pagination">
        <span>{{ paginationText }}</span>
        <button @click="prevPage" :disabled="!canGoBack">❮</button>
        <button @click="nextPage" :disabled="!canGoForward">❯</button>
      </div>
    </div>
    <app-messages :messages="inbox"></app-messages>
  </div>
</template>

<script>
// import { bus } from "../../../main";
import Messages from "./messages.vue";
import { fetchInboxMessages } from "./data/messages";

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    mailConfiguration: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      inbox: [], // Store fetched messages here
      totalMessages: 0,
      pageSize: 15,
      currentPage: 1,
      nextPageToken: null,
      prevPageTokens: [], // Stack for previous pages
      loading: false, // Track loading state
    };
  },
  created() {
    this.getMessages();
  },
  watch: {
    inbox(newInbox) {
      this.$emit("messages-updated", newInbox); // Emit when inbox changes
    }
  },
  // activated() {
  //   // Refresh component data when it is activated
  //   this.getMessages();
  // },
  methods: {
    async getMessages(pageToken = null) {
      try {
        this.loading = true; // Start loading
        // Fetch messages and assign them to `inbox`
        let data = await fetchInboxMessages(
          this.mailConfiguration.service,
          this.mailConfiguration.user._id,
          this.pageSize,
          pageToken
        );
        if (data.emails.length > 0) {
          this.totalMessages = Math.max(
            this.totalMessages || 0,
            (this.currentPage - 1) * this.pageSize + data.emails.length
          );
        }

        this.nextPageToken = data.nextPageToken;
        // this.inbox = [...data];
        this.$set(this, "inbox", data.emails);
        this.$emit("messages-updated", this.inbox); // Emit updated messages
      } catch (error) {
        console.error("Failed to fetch messages:", error);
      } finally {
        this.loading = false; // Stop loading
      }
    },
    nextPage() {
      if (this.nextPageToken) {
        this.prevPageTokens.push(this.nextPageToken); // Store current token before fetching next
        this.currentPage++;
        this.getMessages(this.nextPageToken);
      }
    },
    prevPage() {
      if (this.prevPageTokens.length > 1) {
        // Ensure there's at least 2 tokens to go back
        this.currentPage--;
        const prevToken = this.prevPageTokens[this.prevPageTokens.length - 2];
        this.prevPageTokens.pop(); // Remove last token
        this.getMessages(prevToken);
      } else {
        this.currentPage = 1;
        this.getMessages(null); // Load first page with no token
      }
    },
  },
  computed: {
    incomingMessages() {
      // Keep this in case other logic uses it
      return this.data.messages.filter(function (message) {
        return message.type === "incoming" && !message.isDeleted;
      });
    },
    paginationText() {
      let start = (this.currentPage - 1) * this.pageSize + 1;
      //let end = Math.min(this.currentPage * this.pageSize, this.totalMessages);
      let end = this.currentPage * this.pageSize;
      if (!this.nextPageToken) {
        end = Math.min(end, this.totalMessages); // Stop at total messages if no next page
      }
      return `${start}-${end} of ${this.totalMessages || "Many"} `;
    },
    canGoBack() {
      return this.prevPageTokens.length > 0;
    },
    canGoForward() {
      return !!this.nextPageToken;
    },
  },
  components: {
    appMessages: Messages,
  },
};
</script>

<style scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
}

.pagination {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
}

button {
  padding: 5px 10px;
  border: none;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  border-radius: 5px;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
</style>
